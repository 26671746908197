import * as React from "react"
import styled from "@emotion/styled"
import { format } from "date-fns"

export interface PostItem {
  tags: {
    name: string
  }
  content: string
  excerpt: string
  slug: string
  title: string
  id: string
  date: string
}

interface Props {
  post: PostItem
  className?: string
}

const Post = (props: Props) => {
  const newsItemURLPart = props.post.slug ? props.post.slug : props.post.id

  return (
    <Root className={props.className}>
      <Top>
        <Title
          dangerouslySetInnerHTML={{ __html: props.post.title }}
          href={`/${newsItemURLPart}`}
        />
        {
          props.post.date ? (
            <PostDate>
              {
                format(new Date(props.post.date), "dd-MM-yyyy")
              }
            </PostDate>
          ) : null
        }

      </Top>

      <Content dangerouslySetInnerHTML={{ __html: props.post.content }} />
    </Root>
  )
}

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const PostDate = styled.div``
const Title = styled.a`
  padding: 8px;
  font-size: 1.3em;
  font-weight: bold;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0.5em;
  }

  figure {
    margin: 2px;
  }

  a {
    color: rgb(71, 153, 54);

    &:hover {
      color: rgb(51, 92, 33);
    }
  }
`
const Root = styled.div`
  border: 3px solid rgb(71, 153, 54);
  border-radius: 2%;
`
export default Post
