import React from "react"
import Layout, { Title } from "../components/layout"
import SEO from "../components/seo"
import RehypeReact from "rehype-react"
import Video from "../components/video"
import Popup from "../components/popup"
import RecentPosts from "../components/recent-posts/RecentPosts"

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    "video-player": Video,
    "popup": Popup,
    "recent-posts": RecentPosts,
  },
}).Compiler

const pageTemplate = (props) => {
  const { frontmatter, htmlAst, content, title, path, lang } = props.pageContext.data
  const seoTitle = title || (frontmatter && frontmatter.title)
  return (
    <Layout currentPath={frontmatter ? frontmatter.path : path} lang={frontmatter ? frontmatter.lang : lang}
            pathEn={frontmatter ? frontmatter.path_en : "/"}
            pathEs={frontmatter ? frontmatter.path_es : "/"}>
      <Title dangerouslySetInnerHTML={{ __html: title }}/>
      <SEO title={`${seoTitle ? `${seoTitle} | ` : ""}Di No A Las Torres`}/>
      {
        htmlAst ? renderAst(htmlAst) : null
      }
      {

        content ? <div dangerouslySetInnerHTML={{ __html: content }}/> : null
      }
    </Layout>
  )
}

export default pageTemplate
