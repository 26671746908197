import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Post, { PostItem } from "./Post"
import { useLanguage } from "../../LanguageContext"
import { NewsNode } from "../../pages/blog"

interface Props {
  posts: PostItem
}

const RecentPosts = props => {
  const lang = useLanguage()
  const multilangRecentPosts = useStaticQuery(graphql`
    {
      allWordpressPost(
        filter: { tags: { elemMatch: { name: { eq: "recent" } } } }
      ) {
        nodes {
          tags {
            name
          }
          content
          excerpt
          slug
          title
          id
          date
        }
      }
    }
  `)

  const recentPosts = {
    en: [],
    es: [],
  }

  const postNodes = multilangRecentPosts.allWordpressPost.nodes
  postNodes.sort((a: NewsNode, b: NewsNode) => {
    const aDateTag = a.tags.find(tag => /d{4}-d{2}-d{2}/)
    const bDateTag = b.tags.find(tag => /d{4}-d{2}-d{2}/)
    if (aDateTag?.name < bDateTag?.name) {
      return 1
    } else if (aDateTag > bDateTag) {
      return -1
    } else {
      return 0
    }
  })
  postNodes.forEach(node => {
    const tags = node.tags.map(t => t.name)
    if (tags.includes("english")) {
      recentPosts.en.push(node)
    }
    if (tags.includes("spanish")) {
      recentPosts.es.push(node)
    }
  })

  const title = {
    en: "Latest news",
    es: "Ultimas novedades"
  }[lang]
  return (
    <Root>
      <Title>{title}:</Title>
      <Posts>
        {recentPosts[lang].map((post: PostItem) => (
          <StyledPost post={post} />
        ))}
      </Posts>
    </Root>
  )
}

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-underline: black;
  margin: 42px;
  text-transform: uppercase;
  
`
const Root = styled.div``
const StyledPost = styled(Post)`
  transition: background-color 0.1s ease;
  padding: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const Posts = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: wrap;
  div {
    margin-right: 5px;
    margin-bottom: 10px;
  }

  justify-content: center;
`
export default RecentPosts
