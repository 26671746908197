import React from "react"
import ReactPopup from "reactjs-popup"
import styled from '@emotion/styled'
import closeButton from './close-button.svg'

const Popup = ({ children, delay, style }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, delay * 1000)
  }, [delay])


  return (
    <StyledPopup open={isOpen} closeOnDocumentClick onClose={() => setIsOpen(false)}>
      <CloseButton onClick={() => setIsOpen(false)} src={closeButton}>

      </CloseButton>
      <Image style={style}>
        { children }
      </Image>
    </StyledPopup>

  )
}

const StyledPopup = styled(ReactPopup)`
  .popup-content {
    width: 90% !important;
    max-width: 850px;
  }
`
const CloseButton = styled.img`
  width: 40px;
  height: 40px;
  z-index: 1;
  font-size: 42px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: white;
  &:hover {
    opacity: 0.6;
  }
`
const Image = styled.div`
    pointer-events: none;
    .gatsby-resp-image-wrapper {
        max-width: inherit;
    }
`

export default Popup
