import React from "react"
import playButtonRed from "../components/play-button-red.svg"
import styled from "@emotion/styled"

const Video = ({ url, title, children }) => (
  <Root>
    <PlayButton href={url} target="__blank" rel="nofollow"/>
      <Image>
        {
          children
        }
      </Image>
    <VideoTitle>
      {title}
    </VideoTitle>
  </Root>)

const Image = styled.div`
    pointer-events: none;
    .gatsby-resp-image-wrapper {
        max-width: inherit;
    }
`
const VideoTitle = styled.div`
  opacity: 1;
  text-align: center;
  color: rgba(255,255,255,.9);
  top: 20px;
  position: absolute;
  width: 100%;
  font-width: 400;
  background-color: rgba(204, 39, 39, 0.4);
  padding: 4px 0 4px 0;
`
const Root = styled.div`
  position: relative;
  &:hover {
    ${Image} {
        opacity: 0.6;
    }
    ${VideoTitle} {
      color: white;
    }
  }
`

const PlayButton = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url(${playButtonRed}); 
  &:hover {
    background-image: url(${playButtonRed}); 
  }
  background-repeat: no-repeat;
  background-size: 48px 48px;
  z-index: 1;
`

export default Video
